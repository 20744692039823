// Returns list of degree abbreviations (MD, MBA, etc) for card titles
export function getDegreeAbbreviations(degrees) {
  if (!degrees || degrees.length == 0) {
    return '';
  }

  let degreeString = ', '

  // Degree abbreviation will be text between parenthesis, i.e. Medical Doctor (MD)
  for (const [i, degree] of degrees.entries()) {
    // Matches any text between an open and close parenthesis
    degreeString += degree.match(/\(([^)]+)\)/)[1];
    if (i < degrees.length - 1) {
      degreeString += ', '
    }
  }

  return degreeString;
}

export function initializeFamilyPlanningBanner() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const query = urlParams.get('query');

  const keywords = [
    'abortion',
    'family planning',
    'pregnancy termination',
    'medical abortion',
    'surgical abortion'
  ];

  const bannerEl = document.querySelector('.js-answersFamilyPlanningBanner');

  const shouldHide = !keywords.some(keyword => query && query.toLowerCase().includes(keyword));

  if (shouldHide || (query === null || query === undefined)) {
    bannerEl.style.display = 'none';
  } else {
    bannerEl.style.display = 'block'; // Show the element if any keyword is present
  }
}
